:root {
  --white: #ffffff;
  --black: #000000;
  --red: #d0021b;
  --orange: #ff6100;
  --dark: #0f1721;
  --grey-dark: #5a6276;
  --grey: #acb3ba;
  --grey-light: #f2f6fb;
  --light-green: #55ba00;
  --dark-green: #00883a;
  --blue: #578df5;
  --green-gradient: linear-gradient(180deg, #55ba00 0%, #00883a 100%);
  --red-gradient: linear-gradient(136.05deg, #ed4747 12.16%, #e30b0b 86.37%);
  --button-gradient: linear-gradient(
    90deg,
    #55ba00 0,
    #00883a 25%,
    #55ba00 75%,
    #00883a
  );
  --essential-gradient: linear-gradient(63deg, #b34126 0%, #e69742 100%);
  --manager-gradient: linear-gradient(63deg, #421871 0%, #68257e 100%);
  --pro-gradient: linear-gradient(63deg, #a82624 0%, #d5514b 100%);
  --scan-gradient: linear-gradient(63deg, #0b2555 0%, #0b3286 100%);
  --cuntrion-gradient: linear-gradient(63deg, #070707 0%, #424242 100%);
  --br-inputs: 12px;
  --br-circle: 50%;
  --box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  --challenges-gold: #ffc12d;
  --grey-blue: #7a86a1;
}
