@import '../../../../assets/styles/mixins';

.auth-main {
    width: 100%;
    height: 100%;
    //background-color: white;
    padding: 10rem;
    max-width: 1440px;

    .auth-l {
        width: 50%;
        height: 100%;

        img {
            width: unset;
            max-height: 110px;
        }

        h2 {
            padding: 25px 0;
        }

        .auth-btns {
            width: 100%;

            button {
                width: 35%;
                color: white;
                background: var(--green-gradient);
                margin-right: 10px;
                margin-top: 25px;
            }

            & .btn-black {
                background-color: white;
                background: none;
                border: 1px solid var(--black);
                color: var(--black);
            }
        }
    }

    .auth-r {
        width: 50%;
        height: 100%;

        img {
            width: 90%;
            height: 80%;
        }
    }



    .arrow-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;

        .t-r-arr {
            position: absolute;
            top: 0;
            right: 0;
        }

        .b-l-arr {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.auth-login {
    width: 100%;
    height: 100vh;
    // background-color: white;

    position: relative;
    min-height: 850px;
    overflow-y: auto;

    @include mobile {
        width: 100%;
        min-height: unset;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    }


    input:-webkit-autofill {
        -webkit-text-fill-color: black !important;
        font-size: 16px;
    }

    .left {
        width: 60%;
        height: 100%;

        img {
            width: 95%;
            max-height: 950px;
            height: 80%;
        }
    }


    .signup-container {
        width: 100%;
        height: 100%;

        .signup-img {
            width: 50%;
            height: 100%;
            padding: 20px;

            @include mobile-tablet {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                max-width: 800px;
            }
        }


        .auth-container {
            padding: 20px;
            width: 50%;
            height: 100%;

            .auth-form-wrapper {
                width: calc(100% - 100px);
                max-width: 600px;
                margin: 50px 50px 0;

                @include mobile-tablet {
                    width: calc(100% - 40px);
                    max-width: unset;
                    margin: 20px 20px 0;
                }
            }

            @include mobile-tablet {
                width: 100%;
            }
        }

        .signup-text-container {
            margin-bottom: 30px;
        }
    }

    .auth-logo-signup {
        height: 120px;
        width: 100%;
        padding-left: 20px;
        border-bottom: 1px var(--grey-light) solid;
        background-color: white;
        z-index: 1;

        img {
            width: 160px;
        }
    }

    .auh-logo {
        height: 120px;
        min-height: 120px;

        @include mobile {
            width: 100%;
            height: 80px;
            min-height: unset;
        }

        img {
            width: 160px;
        }

        @include mobile {
            width: 100%;
            height: 80px;
            min-height: 80px;

            img {
                width: 100px;
            }
        }
    }

    .auth-container {
        width: 550px;
        padding: 2rem 55px;
        box-shadow: 0 0 60px #0000000A;
        margin-bottom: 2rem;
        position: relative;

        @include mobile {
            width: 100%;
            padding: 2rem 20px;
            margin: unset;
        }

        h2 {
            padding-bottom: 10px;
        }

        p {
            color: var(--light-green);
            padding: 10px;
        }

        .close-back {
            position: absolute;
            left: 2rem;
            top: 2.5rem;
            cursor: pointer;
        }

        .two-factor-auth {
            width: 100%;

            h5 {
                font-weight: 400;
            }

            .separator {
                width: 100%;



                .line {
                    width: 35%;
                    height: 2px;
                    background-color: rgba(176, 176, 176, 0.262);
                }

                h5 {
                    width: 30%;
                    color: black;
                    text-align: center;
                    font-weight: 400;

                }
            }


            .tfa-card {
                width: 90px;
                height: 60px;
                border-radius: 10px;
                background-color: #F2F5F7;

                margin: 15px 5px;
                user-select: none;
                cursor: pointer;


                svg {
                    width: 20px;

                }
            }

            a {
                color: var(--light-green);
                font-weight: 600;
            }
        }

        .form-auth {
            width: 100%;
            padding: 10px 0;

            h5 {
                font-weight: 600;
            }

            .f-container {
                width: 100%;
                margin-bottom: 0.5rem;

                h3 {
                    font-weight: 600;
                }


                .f-name {
                    width: 100%;

                    .f-col {
                        width: 49%;
                    }
                }
            }

            .f-input {
                width: 100%;
                height: 45px;
                border-radius: var(--br-inputs);
                background-color: #ffffff;
                margin: 10px 0;
                border: 1px solid #E0DFE2;
                padding: 10px;


                .f-input-phone {
                    width: 100%;
                    height: 60px;
                }

                .PhoneInput {
                    width: 100%;
                }

                input {
                    width: 100%;
                    height: 100%;
                    background-color: #ffffff;
                    border: none;
                    outline: none;
                    color: black;
                    font-size: 16px;

                }

                ::placeholder {
                    color: #E0DFE2;
                    font-size: 16px;
                }
            }

            .captcha {
                width: 100%;
                margin: 10px 0;
            }

            button {
                width: 100%;
                margin: 10px 0;
                color: white;
                height: 50px;
            }

            .error {
                text-align: left;
                color: red;
                font-size: 12px;
                width: 100%;
                overflow-wrap: break-word;

            }

            .success {
                text-align: center;
                color: var(--light-green);
                font-size: 14px;
                width: 100%;
                padding: 10px 0;
            }

            .f-pass {
                width: 100%;
                // padding-bottom: 35px;
                cursor: pointer;

                h5 {
                    width: 50%;
                }

                a {
                    width: 50%;
                    text-decoration: underline;

                    h5 {
                        width: 100%;
                        text-align: right;
                        font-size: 14px;
                        color: #5D5C61;
                    }
                }
            }

            & .resend {
                color: var(--light-green);

                h5 {
                    font-size: 14px;
                    text-align: right;

                }
            }
        }
    }

    .getapp-popup {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;
        width: 100%;

        img {
            height: 60px;
            margin-left: 10px;

        }

        h2 {
            font-size: 16px;
            padding-right: 10px;
        }
    }

    .arrow-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;

        .t-r-arr {
            position: absolute;
            top: 0;
            right: 0;
        }

        .b-l-arr {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

}

.home {
    height: 100%;
    width: 100%;

    button {
        width: 35%;
        color: white;
        background: var(--green-gradient);
        margin-right: 10px;
        margin-top: 25px;
    }


}

.PhoneInputCountry {
    padding-right: 10px;
    padding-left: 5px;
    border-right: 2px solid #E0DFE2;

    .PhoneInputCountryIcon {
        margin-right: 10px;
        aspect-ratio: 1;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;

        .PhoneInputCountryIconImg {
            object-fit: cover;
        }
    }
}