@import "./mixins";

.mainContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.arya-btn {
  height: 60px;
  border-radius: var(--br-inputs);
  font-size: 16px;
  font-weight: 600;
}

.not-found {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  h2 {
    padding: 20px;
  }

  button {
    width: 300px;
    color: white;

    @include mobile {
      width: 80%;
    }
  }
}

.filter-c {
  width: 160px;
  height: 35px;
  border-radius: 50px;
  background: #f7f7f7;
  cursor: pointer;
  user-select: none;
  position: relative;
  margin-right: 10px;

  h4 {
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    color: #89939f;
    width: 90%;
  }

  .filter-i {
    width: 100%;
    padding: 0 15px;
    height: 100%;
  }

  .filter-m {
    position: absolute;
    width: calc(100% + 1px);
    top: 35px;
    height: unset;
    min-height: 150px;
    max-height: 200px;
    background: #f7f7f7;
    border-radius: 0 0 4px 4px;
    padding: 15px;
    z-index: 35;
    border: 1px solid white;
    border-top: 0;
    overflow-y: auto;

    .filter-s {
      width: 100%;
      margin: 10px 0;
    }

    .radio-c {
      width: 14px;
      height: 14px;
      min-width: 14px;
      border-radius: var(--br-circle);
      border: 2px solid #f7f7f7;
      background-color: #f7f7f7;
      margin-right: 10px;
      outline: 1px solid #89939f;
    }

    .radio-active {
      background-color: #1ac385;
    }
  }
}

.popup-container {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
}

.popup-container-main {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  background-color: #00000033;
}

.popup-container-second {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 20;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  background-color: #00000033;
}

.green-gradient-button-bg {
  background: var(--button-gradient);
  background-size: 400%;
}

.platinum-gradient-bg {
  background: var(--platinum-gradient);
}

.green-gradient-button {
  height: 35px;
  border-radius: 17px;
  font-size: 12px;
  padding: 0 20px;
  color: black;
  background: var(--button-gradient);
  background-size: 400%;

  &:disabled {
    background: #c0bdcc;
  }
}

.red-gb {
  background: var(--red-gradient);
}

.outlined-button {
  height: 35px;
  border-radius: 17px;
  font-size: 12px;
  padding: 0 25px;
  color: #5a6276;
  border: 1px #ebebeb solid;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.disabled-button {
  background-color: #bebebe;
  color: #343434;
  font-weight: bold;
  border-radius: 15px;
}

.text-button {
  background-color: transparent;
  border: none;
  border-bottom: 1px #5a6276 solid;
  padding: 0;
}

.big-card {
  aspect-ratio: 713/370;
  width: calc(50% - 20px);
  padding: 25px 0 25px 25px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  max-height: 300px;
  min-height: 250px;

  &.background-image {
    background-size: cover;
    background-repeat: no-repeat;
  }

  @include mobile {
    width: 100%;
    margin: 7px 0;
    padding: 25px 0 25px 25px;
    max-height: unset;
    min-height: 150px;
    aspect-ratio: unset;
  }

  .card-left-side {
    color: white;
    width: 70%;

    @include tablet {
      flex: 2;
    }

    @include mobile {
      //flex: 2;
    }

    .cc-title {
      color: white;
      margin-bottom: 15px;

      @include tablet {
        font-size: 20px;
        margin-bottom: 10px;
      }

      @include mobile {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .description {
      @include tablet {
        font-size: 14px;
        line-height: 19px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .card-right-side {
    height: 100%;
    flex: 1;

    .image {
      width: 100%;
      height: 100%;

      img {
        height: auto;
        max-height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.small-card {
  width: calc(25% - 20px);
  aspect-ratio: 343/370;
  background: white;
  margin: 0 10px 30px;
  display: inline-block;
  vertical-align: top;
  max-height: 300px;
  min-height: 250px;

  @include tablet {
    width: 30%;
  }

  @include mobile {
    width: 100%;
    margin: 10px 0 20px;
    max-width: unset;
    min-width: unset;
  }

  //@media (min-width: 320px) and (max-width: 765px) {
  //  width: 100%;
  //  margin: 10px 0 20px;
  //  max-width: unset;
  //  min-width: unset;
  //}

  .wrapper {
    width: 100%;
    height: 100%;
    padding: 20px 0;
  }
}

.description-text {
  font-size: 12px;
  color: #5a6276;
  text-align: center;
}

.subscription-tag {
  width: 75px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 1000px;
  background-color: #00000033;
  padding: 3px 0;
  border: 1px solid #d0d0d0;

  &.silver {
    background: #dbdbdb;
  }

  &.gold {
    background: linear-gradient(107deg, #ffda48 0%, #ed9c1d 100%);
    border: 1px solid transparent;
  }

  &.platinum {
    background: linear-gradient(
      90deg,
      #d3d4d6 0%,
      #fcfcfc 20%,
      #cdcdcd 48%,
      #ebebeb 72%,
      #d2d3d5 100%
    );
  }
}

.gradient-button {
  background-size: 300%;
}

.gradient-button:hover,
.gradient-button:focus {
  background-position: right;
}
.item-description {
  h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    background: whitesmoke;
    padding: 16px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}
